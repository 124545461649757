import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { ToastProgrammatic as Toast } from 'buefy';
import { StudyLevelCreateBaseDetails, StudyLevelUpdateBaseDetails, StudyLevelResponse } from '@/models/study_level';

@Module({
    namespaced: true,
    dynamic: true,
    name: `study_level.detail`,
    store,
})
class StudyLevelDetailStore extends VuexModule {
    isLoading = false;
    error: any = null;
    response: string | null = null;
    deleteResponse: string | null = null;

    @Mutation
    setError(payload: any) {
        this.error = payload;
    }

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    setResponse(payload: string | null) {
        this.response = payload;
    }

    @Mutation
    setDeleteResponse(payload: string | null) {
        this.deleteResponse = payload;
    }

    @Action
    updateCurrentStudyLevel(details?: StudyLevelUpdateBaseDetails) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setResponse`, null);
        
        const payload: StudyLevelCreateBaseDetails = {
            name: details!.name
        };

        axios.put<StudyLevelResponse>(`/study-level/${details?.id}`, payload).then((response) => {
            if (response.data.code === 200) {
                this.context.commit(`setResponse`, response.data.data);
                Toast.open({
                    type: `is-success`,
                    duration: 3000, // 3 Seconds
                    message: `Successfully updated study level.`,
                });
            }

            if (response.data.code >= 400) {
                Toast.open({
                    type: `is-danger`,
                    duration: 3000, // 3 Seconds
                    message: `There is an issue with updating study level data. Please try again`,
                });
            }
        })
        .catch((error) => {
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }

    @Action
    deleteCurrentStudyLevel(studyLevelId: number) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setDeleteResponse`, null);

        axios.delete<StudyLevelResponse>(`/study-level/${studyLevelId}`).then((response) => {
            if (response.data.code === 200) {
                this.context.commit(`setDeleteResponse`, response.data.data);
                Toast.open({
                    type: `is-success`,
                    duration: 3000, // 3 Seconds
                    message: `Successfully delete study level.`,
                });
            }

            if (response.data.code >= 400) {
                Toast.open({
                    type: `is-danger`,
                    duration: 3000, // 3 Seconds
                    message: `There is an issue with deleting study level data. Please try again`,
                });
            }
        })
        .catch((error) => {
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }
}

export default getModule(StudyLevelDetailStore);














import { Vue, Component } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import BackButtonLayout from '@/layouts/management/BackButtonLayout.vue';
import { UserNotificationStores } from '@/store/user_notifications';
import UserNotificationBatchForm from '@/components/user_notifications/UserNotificationBatchForm.vue';
import { omit } from 'lodash';

@Component({
  components: {
    Page,
    BackButtonLayout,
    UserNotificationBatchForm,
  },
}) 
export default class EditUserNotificationTypes extends Vue {
  userNotificationStore = UserNotificationStores;

  unsubscribe: () => void = () => {};

  get userNotificationBatch() {
    return this.userNotificationStore?.userNotificationBatch;
  }

  mounted() {
    this.userNotificationStore.getUserNotificationBatch(Number(this.$route.params.id));
  }

    created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'user_notification/UPDATE_USER_NOTIFICATION_BATCH_SUCCESS') {
        this.$router.replace('/notification_batches');
      }
    });
  }

  handleSubmit(payload: any) {
    this.userNotificationStore.updateUserNotificationBatch({
        id: Number(this.$route.params.id),
      ...(omit(payload, ['id', 'customattributes', 'timecreated', 'timemodified']) as any),
    });
  }
}

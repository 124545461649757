




























































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Upload from '@/components/common/Upload.vue';
import { UserNotificationBatchResponse, CreateUserNotificationBatchFormPayload } from '@/models/user_notification_batches';
import { ValidationObserver } from 'vee-validate';
import snakeCase from 'lodash/snakeCase';
import Dropdown from '@/components/common/Dropdown.vue';
import Multiselect from 'vue-multiselect';
import { UserNotificationStores } from '@/store/user_notifications';
import { DropdownItem } from '@/models/dropdown';
import { UserStores } from '@/store/users';
import moment from 'moment';
import { StudyLevelStores } from '@/store/study_level';

const actionType = 'EXTERNAL_LINK';
@Component({
  components: {
    Dropdown,
    Upload,
    ValidationObserver,
    Multiselect,
  },
})
export default class UserNotificationBatchForm extends Vue {
  userNotificationStore = UserNotificationStores;
  studyLevelListStore = StudyLevelStores.list;
  userListStore = UserStores.list;
  minimumDate = new Date();

  @Prop({ default: false })
  isEdit!: boolean;

  @Prop({ default: '' })
  defaultValues!: UserNotificationBatchResponse['data']['objects'];

  studyLevels: DropdownItem[] = [];
  formData: CreateUserNotificationBatchFormPayload = {
    title: '',
    content: '',
    targettype: '',
    targets: [],
    scheduledat: null,
    type: '',
    actiontype: '',
    actionurl: '',
    actionid: '',
  };
  notificationTypeData: DropdownItem[] = [];
  userData: DropdownItem[] = [];
  selectedNotificationType = '- Select -';
  previousSelectedNotificationType = '';
  selectedTargetType = '- Select -';
  previousSelectedTargetType = '';

  hasRedirectAction = false;
  selectedUser: any[] = [];
  selectedForm: any[] = [];

  get notificationTypelist() {
    return this.notificationTypeData;
  }

  get targetTypes() {
    return [
      {
        text: 'Individual',
        value: 'INDIVIDUAL',
      },
      {
        text: 'Forms',
        value: 'GROUP_FORM',
      },
      {
        text: 'Free students',
        value: 'GROUP_FREE',
      },
      {
        text: 'Paid students',
        value: 'GROUP_PAID',
      },
    ];
  }

  fetchNotifcationTypes() {
    this.userNotificationStore.getUserNotificationTypes({
      page: 1,
      limit: 100,
    });
  }

  fetchStudyLevels() {
    this.studyLevelListStore.retrieveStudyLevels({
        page: 1,
        limit: 100,
    });
  }

  onNotificationTypeSelect(item: DropdownItem) {
    this.updateNotificationTypeState(item);
  }

  onTargetTypeSelect(item: DropdownItem) {
    this.updateTargetTypeState(item);
  }

  onTargetSelect(item: DropdownItem) {
    this.updateTargetsState(item);
  }

  updateTargetTypeState(item: DropdownItem) {
    this.selectedTargetType = item.text;
    this.formData.targettype = item.value;
  }

  updateNotificationTypeState(item: DropdownItem) {
    this.selectedNotificationType = item.text;
    this.formData.type = item.value;
  }

  updateTargetsState(item: DropdownItem) {
    this.formData.targets.push(item.value);
  }

  getUserDropdown(keyword: string) {
    if(keyword.length > 3) {
      this.userListStore.retrieveUsers({
        page: 1,
        limit: 20,
        keyword: keyword,
        filter: 'student',
      });
    }
  }

  mounted() {
    this.fetchNotifcationTypes();
    this.fetchStudyLevels();

    if (this.isEdit) {
      this.selectedNotificationType = this.defaultValues.type;
      this.selectedTargetType = this.targetTypes.find((t) => t.value === this.defaultValues.targettype)?.text || '';
      
      if(this.defaultValues.customattributes && this.defaultValues.customattributes.actiontype === actionType) {
        this.hasRedirectAction = true;
      }

      if(this.selectedTargetType === 'Individual') {
        this.userListStore.retrieveUsers({
          page: 1,
          limit: 20,
          ids: this.defaultValues.targets.map((t:any) => t.id).join(','),
        });
      } else if(this.selectedTargetType === 'Forms') {
        this.selectedForm = this.defaultValues.targets.map((t:any) => {
          return {
            text: t,
            value: t,
            uniqueKey: `${t.id}_`,
          };
        });
      }

      this.formData = {
        ...this.defaultValues,
        ...(this.defaultValues.customattributes && {
          ...this.defaultValues.customattributes,
        }),
        scheduledat: this.defaultValues.scheduledat ? moment(this.defaultValues.scheduledat).toDate() : null,
      };
    }
  }

  onSubmit() {
    const { ...formData } = this.formData;
    if(this.hasRedirectAction && formData.actionurl.length > 0) {
      formData.actiontype = actionType;
    } else {
      formData.actiontype = '';
      formData.actionurl = '';
    }

    if(!this.isEdit) {
      formData.type = snakeCase(formData.type).toUpperCase();
    }

    formData.scheduledat = formData.scheduledat ? moment(formData.scheduledat).format('YYYY-MM-DD HH:mm:ss') : null;

    this.$emit('submit', {
      ...formData,
    });
  }

  @Watch(`studyLevelListStore.response`)
  retrieveStudyLevels() {
    this.studyLevels = [];
    if (this.studyLevelListStore.response) {      
      this.studyLevelListStore.response.objects.forEach((item) => {
        this.studyLevels.push({
          text: item.name,
          value: item.name,
          uniqueKey: `${item.id}_`,
        });
      });
    }
  }

  @Watch(`userNotificationStore.userNotificationTypes`)
  updateNotificationTypeDropdown() {
    if(this.userNotificationStore.userNotificationTypes.data) {
      this.notificationTypeData = [];
      this.userNotificationStore?.userNotificationTypes.data.forEach((t) => {
        this.notificationTypeData.push({
          text: t.type,
          value: t.type,
          uniqueKey: `${t.id}_`,
        });
      });
    }
  }

  @Watch(`userListStore.response`)
  setUsers() {
    if (this.userListStore.response) {
      this.userData = [];
      this.userListStore.response.objects.forEach((item) => {
        this.userData.push({
          text: item.name  + " / " + item.email,
          value: item.id.toString(),
          uniqueKey: `${item.id}_`,
        });
      });

      if(this.isEdit && this.selectedTargetType === 'Individual' && this.defaultValues.targets) {
        this.userListStore.response.objects.forEach((item) => {
          this.selectedUser.push({
            text: item.name  + " / " + item.email,
            value: item.id.toString(),
            uniqueKey: `${item.id}_`,
          });
        });
      }
    }
  }
}
